
// import {api} from '@/util/request'

// const apiRequest = {
//     getRouters(parm){
//         return api.get('/getRouters', parm)
//     }
// }

// export default apiRequest
import jsonToFormData from '@/util/jsonToFormData'


import {api_request} from '@/util/request'

const apiRequest = {
    login(parm){ // 登录
        return api_request({
            url: '/login', 
            method: 'post', 
            parm , 
            isToken: false
        })
    },
    captchaImage(parm){ // 图片验证码
        return api_request({
            url: '/captchaImage', 
            method: 'get', 
            parm , 
            isToken: false
        })
    },
    getInfo(parm){ // 获取用户信息
        return api_request({
            url: '/getInfo', 
            method: 'get', 
            parm ,
        })
    },
    getRoutersNew(parm){//获取导航栏(新)
        return api_request({
            url: '/getRoutersNew', 
            method: 'get',
            parm
        })
    },
    editUser(parm){ // 编辑用户信息
        return api_request({
            url: '/system/user', 
            method: 'put', 
            parm ,
        })
    },
    editAvatar(parm){ // 编辑用户头像
        return api_request({
            url: '/system/user/editAvatar', 
            method: 'put', 
            parm: jsonToFormData(parm),
        })
    },
    getCustomList(parm){ // 获取客户信息列表
        return api_request({
            url: '/srm/custom/list', 
            method: 'get',
            parm ,
        })
    },
    getFactoryAreaMap(parm){ // 获取厂区列表
        return api_request({
            url: '/gqManage/factoryArea/getFactoryAreaMap', 
            method: 'get',
            parm ,
        })
    },
    getFactoryAreaNoPerm(parm){ // 获取厂区列表（无权限限制）
        return api_request({
            url: '/gqManage/factoryArea/getAreaNoPerm', 
            method: 'get',
            parm ,
        })
    },
    getTreelist(parm){ // 获取物料品类列表
        return api_request({
            url: '/gqManage/matter/treelist', 
            method: 'get',
            parm ,
        })
    },
    getProvince(parm){ // 获取城市列表
        return api_request({
            url: `/system/dept/province`, 
            method: 'get',
            parm ,
        })
    },
    addCustom(parm){ // 添加客户
        return api_request({
            url: `/srm/custom/addOptional`, 
            method: 'post',
            parm ,
        })
    },
    editCustom(parm){ // 编辑客户
        return api_request({
            url: `/srm/custom/editOptional`, 
            method: 'put',
            parm ,
        })
    },
    delCustom(parm){ // 删除客户
        return api_request({
            url: `/srm/custom/logicalDeletion/${parm.id}`, 
            method: 'delete',
        })
    },
    getCustom(parm){ // 获取客户信息
        return api_request({
            url: `/srm/custom/${parm.id}`, 
            method: 'get',
        })
    },
    getModifyRecord(parm){ // 获取跟进记录
        return api_request({
            url: `/srm/modifyRecord/list`, 
            method: 'get',
            parm,
        })
    },
    trackRecord(parm){ // 添加跟进记录
        return api_request({
            url: `/srm/trackRecord`, 
            method: 'post',
            parm,
        })
    },
    delTrack(parm){ // 删除跟进记录
        return api_request({
            url: `/srm/modifyRecord/follow/${parm.modifyRecordId}`, 
            method: 'delete',
        })
    },
    getUpstream(parm){ // 获取客户信息跟进人列表
        return api_request({
            url: `/srm/custom/getUpstream`, 
            method: 'get',
            parm,
        })
    },
    getChargeCustom(parm){ // 获取原有人员的负责的公司信息
        return api_request({
            url: `/srm/custom/getChargeCustom`, 
            method: 'get',
            parm,
        })
    },
    getSupplierList(parm){ // 获取供应商信息列表
        return api_request({
            url: `/srm/receivingCompany/list`, 
            method: 'get',
            parm,
        })
    },
    getSupplier(parm){ // 获取供应商详情
        return api_request({
            url: `/srm/receivingCompany/${parm.id}`, 
            method: 'get',
        })
    },
    addSupplier(parm){ // 新增供应商
        return api_request({
            url: `/srm/receivingCompany/addOptional`, 
            method: 'post',
            parm,
        })
    },
    editSupplier(parm){ // 编辑供应商
        return api_request({
            url: `/srm/receivingCompany/editOptional`, 
            method: 'put',
            parm,
        })
    },
    delSupplier(parm){ // 删除供应商
        return api_request({
            url: `/srm/receivingCompany/${parm.id}`, 
            method: 'delete',
        })
    },
    editFollower(parm){ // 修改跟进人或者跟进阶段
        return api_request({
            url: `/srm/receivingCompany/editFollower`, 
            method: 'put',
            parm,
        })
    },
    updateCharge(parm){ // 修改客户跟进人
        return api_request({
            url: `/srm/custom/updateCharge`, 
            method: 'put',
            parm,
        })
    },
    getFollowUpUsers(parm){ // 获取亘前公司市场部人员信息
        return api_request({
            url: `/system/user/followUpUsers`, 
            method: 'get',
            parm,
        })
    },
    exportHzDerive(parm,responseType){ // 合作客户导出
        return api_request({
            url: `/srm/custom/exportHz`, 
            method: 'post',
            parm: jsonToFormData(parm),
            responseType
        })
    },
    exportYxDerive(parm,responseType){ // 潜在客户导出
        return api_request({
            url: `/srm/custom/exportYx`, 
            method: 'post',
            parm: jsonToFormData(parm),
            responseType
        })
    },
    supplierDerive(parm,responseType){ // 供应商导出
        return api_request({
            url: `/srm/receivingCompany/export`, 
            method: 'post',
            parm: jsonToFormData(parm),
            responseType
        })
    },
    supplierExportSelf(parm,responseType){ // 供应商自选导出
        return api_request({
            url: `/srm/receivingCompany/exportSelf`, 
            method: 'post',
            parm: jsonToFormData(parm),
            responseType
        })
    },
    exportRegionStat(parm,responseType){ // 导出物料各省市厂家数量占比统计
        return api_request({
            url: `/srm/receivingCompany/exportRegionStat`, 
            method: 'post',
            parm: jsonToFormData(parm),
            responseType
        })
    },
    recycleDerive(parm,responseType){ // 回收商导出
        return api_request({
            url: `/recycle/export`, 
            method: 'post',
            parm: jsonToFormData(parm),
            responseType
        })
    },
    getRecycleList(parm){ // 获取回收站列表
        return api_request({
            url: `/recycle/list`, 
            method: 'get',
            parm,
        })
    },
    addRecycle(parm){ // 新增回收商
        return api_request({
            url: `/recycle/addOptional`, 
            method: 'post',
            parm,
        })
    },
    editRecycle(parm){ // 编辑回收商
        return api_request({
            url: `/recycle/editOptional`, 
            method: 'put',
            parm,
        })
    },
    delRecycle(parm){ // 删除回收商
        return api_request({
            url: `/recycle/matter/${parm.id}`, 
            method: 'delete',
        })
    },
   getRecycle(parm){ // 获取回收商信息
        return api_request({
            url: `/recycle/${parm.id}`, 
            method: 'get',
        })
    },
    getMaterials(parm){ // 获取物料品类
        return api_request({
            url: `/srm/materials/dept`, 
            method: 'get',
            parm,
        })
    },
    getMaterialsCoding(parm){ // 获取物料编码列表
        return api_request({
            url: `/srm/materialsPrice/list`, 
            method: 'get',
            parm,
        })
    },
    addMaterials(parm){ // 添加物料
        return api_request({
            url: `/srm/materialsPrice`, 
            method: 'post',
            parm,
        })
    },
    editMaterials(parm){ // 编辑物料
        return api_request({
            url: `/srm/materialsPrice`, 
            method: 'put',
            parm,
        })
    },
    delMaterials(parm){ // 删除物料
        return api_request({
            url: `/srm/materials/${parm.id}`, 
            method: 'delete',
            parm,
        })
    },
    changeLoc(parm){ // 拖拽物料
        return api_request({
            url: `/srm/materials/changeLoc`, 
            method: 'put',
            parm,
        })
    },
    upMaterialsPrice(parm){ // 导入物料
        return api_request({
            url: `/srm/materialsPrice/importData`, 
            method: 'post',
            parm: jsonToFormData(parm),
            contentType: 'multipart/form-dat'
        })
    },
    getCustomSource(parm){ // 获取客户来源
        return api_request({
            url: `/srm/custom/getCustomSource`, 
            method: 'get',
            parm,
        })
    },
    getLatestInfo(parm){ // 获取客户历史记录
        return api_request({
            url: `/srm/customHis/latestInfo`, 
            method: 'get',
            parm,
            toast: false
        })
    },
    importHzTemplate(parm,responseType){ // 下载合作客户模版
        return api_request({
            url: `/srm/custom/importHzTemplate`, 
            method: 'post',
            // parm: jsonToFormData(parm),
            responseType
        })
    },
    importErrorLog(parm,responseType){ // 导出错误日志
        return api_request({
            url: `/importErrorLog/export`, 
            method: 'post',
            parm: jsonToFormData(parm),
            responseType
        })
    },
    importDataHz(parm){ // 导入合作客户列表
        return api_request({
            url: `/srm/custom/importDataHz`, 
            method: 'post',
            parm: jsonToFormData(parm),
            contentType: 'multipart/form-dat'
        })
    },
    importYxTemplate(parm,responseType){ // 下载潜在客户模版
        return api_request({
            url: `/srm/custom/importYxTemplate`, 
            method: 'post',
            // parm: jsonToFormData(parm),
            responseType
        })
    },
    importDataYx(parm){ // 导入潜在客户列表
        return api_request({
            url: `/srm/custom/importDataYx`, 
            method: 'post',
            parm: jsonToFormData(parm),
            contentType: 'multipart/form-dat'
        })
    },
    supplierTemplate(parm,responseType){ // 下载供应商模版
        return api_request({
            url: `/srm/receivingCompany/importTemplate`, 
            method: 'post',
            // parm: jsonToFormData(parm),
            responseType
        })
    },
    importSupplier(parm){ // 导入供应商列表
        return api_request({
            url: `/srm/receivingCompany/importData`, 
            method: 'post',
            parm: jsonToFormData(parm),
            contentType: 'multipart/form-dat'
        })
    },
    recycleTemplate(parm,responseType){ // 下载回收商模版
        return api_request({
            url: `/recycle/importTemplate`, 
            method: 'post',
            // parm: jsonToFormData(parm),
            responseType
        })
    },
    importRecycle(parm){ // 导入回收商列表
        return api_request({
            url: `/recycle/importData`, 
            method: 'post',
            parm: jsonToFormData(parm),
            contentType: 'multipart/form-dat'
        })
    },
    upRecyclePrice(parm){ // 导入回收价格表
        return api_request({
            url: `/srm/materialsReference/importData`, 
            method: 'post',
            parm: jsonToFormData(parm),
            contentType: 'multipart/form-dat'
        })
    },
    getRawMaterial(parm){ // 获取原材料列表
        return api_request({
            url: `/srm/receivingCompany/getRawMaterial`, 
            method: 'get',
            parm,
        })
    },
    getExportConfig(parm){ // 获取当前模块的导出配置信息
        return api_request({
            url: `/srm/exportConfig/getExportConfig`, 
            method: 'get',
            parm,
        })
    },
    
}

export default apiRequest
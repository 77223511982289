import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/css/uflex.css';
import '@/assets/css/common.scss';
Vue.use(ElementUI)

import JsonExcel from 'vue-json-excel'
Vue.component('downloadExcel', JsonExcel)
//封装接口的方法
import apiRequest from '@/api/index'
Vue.prototype.$api = apiRequest
//接口域名
import {SITE_URL_API,FILE_SIZE} from '@/config/site'
Vue.prototype.api_url = SITE_URL_API
// 图片大小限制
Vue.prototype.file_size = FILE_SIZE

import findItemById from '@/util/findItemById'
Vue.prototype.findItemById = findItemById
// 出货量列表
import {cargoQuantity} from '@/config/cargoQuantity'
Vue.prototype.cargoQuantity = cargoQuantity
// 出货量列表
import limitJudge from '@/util/includes.js'
Vue.prototype.limitJudge = limitJudge
// 退出登录
import logout from '@/util/logout'
Vue.prototype.logout = logout
//通用方法
import {
	getRowClass,
	findData
} from '@/util/universalMethod'
Vue.prototype.getRowClass = getRowClass
Vue.prototype.findData = findData

Vue.config.productionTip = false

new Vue({
	router,
	store,
	ElementUI,
	render: (h) => h(App),
}).$mount('#app')
